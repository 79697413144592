/** 
 * 大数据分析---产品服务
 */
<template>
  <v-app>
    <v-banner
      single-line
      height="400"
      class="banner-one"
      elevation="0"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/bigDataServiceBanner.png"
          height="100%"
          :transition="false"
          class="d-flex align-center">
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex justify-center align-center">
          <v-card color="transparent" flat width="80%" class="d-flex flex-no-wrap justify-center">
            <div >
              <div>
                <v-card-title class="banner-title white--text font-size-48 mb-6 pa-0 justify-center text-align-center">
                  大数据云分析平台
                </v-card-title>
                <v-card-title class="banner-text white--text  font-size-18 justify-center px-8 pa-0">
                  随时随地分析数据查看结果，让分析无所不能
                </v-card-title>
                <!-- <div class="btnWrap">
                    <p class="banner-btn">立即咨询</p>
                </div> -->
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <!-- 客户数据管理 -->
    <v-card flat class="con3">
      <div class="public-title mb-5">
        <p>DATA MANAGEMENY</p>
        <div>
          <span>客户数据管理</span>
          <span>跨平台采集和整合用户数据为企业挖掘数据价值</span>
        </div>
      </div>
      <div class="box ">
        <v-img
          src="https://h5.ophyer.cn/official_website/other/dataService1.png" contain
        />
      </div>
    </v-card>
    <!-- 数字化智能营销  -->
    <v-card flat class="con3">
      <div class="public-title mb-5" style="margin-top:50px;">
        <p>DIGITAL MARKETING</p>
        <div>
          <span>数字化智能营销</span>
          <span>满足企业的精细化用户运营需求，能够帮助企业以更低成本、更高效的提升收入和用户满意度</span>
        </div>
      </div>
      <div class="box ">
        <v-img
          src="https://h5.ophyer.cn/official_website/other/dataService2.png" contain
        />
      </div>
    </v-card>
    <!-- 数据采集 -->
    <div style="width:1200px;margin:0 auto;">
      <v-card flat class="container3 wrap" >
        <div class="public-title" style="margin-top:50px;">
          <p>PRODUCT</p>
          <div>
            <span>相关产品</span>
          </div>
        </div>    
        <v-tabs v-model="tab1" centered background-color="transparent" class="g-tabs font-size-20">
          <v-tab v-for="(v, k) in data1.wrap1.list" :key="k">
            <b>{{ v.tabTitle }}</b>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab1" class="mt-2" style="background-color: transparent">
          <v-tab-item v-for="(v, k) in data1.wrap1.list" :key="k"
                      :transition="false">
          <div class="con-box">
            <div class="con-item"  v-for="(m, n) in v.children" :key="n">
              <img :src="m.src" alt="">
              <div class="i-tit">{{m.title}}</div>
              <div class="i-text">{{m.text}}</div>
            </div> 
          </div>

          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>

  </v-app>
</template>

<script>
import contentData from '@/static/contentData.js';

export default {
  name: "productService",
    data: () => ({
    data1:contentData.productService,
    tab1: null,
    
  }),
}
</script>

<style lang="scss" scoped>
.container3{
  .con-box{
    display: flex;
    padding-bottom: 60px;
    .con-item{
      // width: 269px;
      text-align: center;
      padding: 0px 30px;
      .i-tit{
        margin: 15px 0;
        font-size: 20px;
        color: #000000;
        line-height: 40px;
      }
      .i-text{
        font-size: 14px;
        color: #666666;
        line-height: 20px;
        padding: 0px 20px;
      }
    }
  }

}

.con3 {
  .box {
    width: 1200px;
    margin: 0 auto;
    margin-top:20px;
  }
}
</style>